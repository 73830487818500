import React from "react";
import logo from "./images/logo.png";
function Navigation() {
  return (
    <nav className="navbar navbar-expand-md navbar  bg-light nav">
      <div className="container-fluid">
        <a className="navbar-brand" href="#home">
          <img className="logo-left log" src={logo} alt=" " />
        </a>
        <ul
          className="collapse navbar-collapse nav navbar-nav me-auto mb-2 mb-lg-0 justify-content-end links"
          id="navbar-brand"
        >
          <li className="nav-item ">
            <a className="nav-link navig-links" href="#home">
              Home
            </a>
          </li>
          <li className="nav-item li-left ">
            <a className="nav-link navig-links" href="#about">
              About Us
            </a>
          </li>
          <li className="nav-item li-left ">
            <a className="nav-link navig-links" href="#services">
              Services
            </a>
          </li>
          <li className="nav-item li-left">
            <a className="nav-link navig-links" href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navigation;
