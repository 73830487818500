import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_27qsfuu",
        "template_7tnxxea",
        form.current,
        "KT-WseLbStuA2ksTI"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent!");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="contact" id="contact">
      <p>Contact Us</p>
      <form className="form" onSubmit={sendEmail} ref={form}>
        <div>
          <div classform="mb-3">
            <label htmlFor="Name" classform="form-label">
              Name
            </label>
            <br />
            <input
              type="text"
              classform="form-control"
              id="user_name"
              name="user_name"
            />
          </div>
          <div classform="mb-3">
            <label htmlFor="exampleInputEmail1" classform="form-label">
              Email address
            </label>
            <br />
            <input
              type="email"
              classform="form-control"
              id="user_email"
              name="user_email"
              aria-describedby="emailHelp"
            />
            <div className="mess" id="emailHelp" classform="form-text">
              We'll never share your email with anyone else.
            </div>
          </div>
          <br />
          <div classform="mb-3">
            <label htmlFor="textBox">Type your message</label>
            <br />
            <textarea name="message" id="textBox" />
          </div>
          <input className="subButton" type="submit" value="Submit" />
        </div>
      </form>
    </div>
  );
}

export default Contact;
