import React from "react";
import routes from "./images/routes.png";
import time from "./images/time.png";
import security from "./images/security.png";
function Services() {
  return (
    <div className="service" id="services">
      <div className="service-title">
        <div>
          <p>Our Goals</p>
        </div>
      </div>
      <div className="cards">
        <div className="card-2">
          <ul className="ul-cards">
            <li className="card-link card-link1">
              <div className="card card-sty">
                <img src={routes} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title card-title-routes">Routes</h5>
                  <p className="card-text">
                    In less than 5 years we have made more than 5000 routes.
                  </p>
                </div>
              </div>
            </li>
            <li className="card-link card-link2">
              <div className="card card-sty">
                <img src={time} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title card-title-time">Time</h5>
                  <p className="card-text">
                    Time is money so we work hard to make possible that 99% of
                    our tranports arrives in time.
                  </p>
                </div>
              </div>
            </li>
            <li className="card-link card-link3">
              <div className="card card-sty">
                <img src={security} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title card-title-security">Security</h5>
                  <p className="card-text">
                    While transporting your goods we make sure that everything
                    arrives safely.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Services;
