import React from "react";
import bg from "./images/background.jpg";
import bgg from "./images/bggg.jpg";
import lg from "./images/carusel.jpg";
function Home() {
  return (
    <React.Fragment>
      <div className="home" id="home">
        <div id="demo" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#demo"
              data-bs-slide-to="0"
              className="active"
            ></button>
            <button
              type="button"
              data-bs-target="#demo"
              data-bs-slide-to="1"
            ></button>
            <button
              type="button"
              data-bs-target="#demo"
              data-bs-slide-to="2"
            ></button>
          </div>

          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={bgg} alt=" " className="d-block w-100 carusel-img" />
            </div>
            <div className="carousel-item">
              <img src={bg} alt=" " className="d-block w-100 carusel-img" />
            </div>
            <div className="carousel-item">
              <img src={lg} alt="" className="d-block w-100 carusel-img" />
            </div>
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#demo"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon"></span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#demo"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon"></span>
          </button>
          <div className="tittle">
            <h1 className="title bg-dark">
              Transporting your goods it's now easier with us!
            </h1>
          </div>
          <div className="bg-lsdar"></div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Home;
