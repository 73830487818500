import React from "react";
import bck from "./images/background.jpg";
function About() {
  return (
    <React.Fragment>
      <div className="bg-light aboutt" id="about">
        <div className="about-tittle">
          <p className="about-title">About us</p>
        </div>
        <div className="about">
          <div className="about-text">
            <p>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum."
            </p>
          </div>
          <div className="line"></div>
          <div className="about-image">
            <img src={bck} alt="" />
          </div>
        </div>
      </div>
      <div className="reckt"></div>
    </React.Fragment>
  );
}
export default About;
